<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							<Button v-if="$can('user-add')" label="Tambah" icon="pi pi-plus" class="p-button-primary mr-2" @click="openNew" />
							<Button v-if="$can('user-delete')" label="Hapus" icon="pi pi-trash" class="p-button-danger" 
								@click="openDelete('multi')" :disabled="!dataTable.selectedData || !dataTable.selectedData.length" />
						</template>
					</Toolbar>

					<!-- Data Table -->
					<DataTable class="p-datatable-gridlines p-datatable-sm" filterDisplay="menu" :rowHover="true" :lazy="true" :paginator="true" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						v-model:filters="dataTable.params.filters"
						v-model:selection="dataTable.selectedData" dataKey="id"
						:rowsPerPageOptions="dataTable.rows_option"
						:value="dataTable.data"
						:rows="dataTable.params.rows" 
						:totalRecords="dataTable.totalRecords" 
						:loading="dataTable.loading" 
						@page="loadData($event)" @sort="loadData($event)">
						<template #header>
							<div class="flex justify-content-between flex-column sm:flex-row">
								<div class="p-fluid">
									<MultiSelect v-model="dataTable.selectedColumns" :options="dataTable.columns"  optionValue="field" optionLabel="header" placeholder="Select Columns" display="chip" style="width: 20em"/>
								</div>
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="dataTable.params.filters['global'].value" @keydown.enter="loadData()" :placeholder="dataTable.global_placeholder" v-tooltip.top.focus="'Press Enter'" style="width: 100%"/>
								</span>
							</div>
						</template>
						<template #empty>
							<div class="p-text-center">No data found.</div>
						</template>
						<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						<!-- Columns -->
						<Column v-for="(col, i) of dataTable.columns.filter(col => dataTable.selectedColumns.includes(col.field))" :field="col.field" :header="col.header" :sortable="col.sort" :showFilterMatchModes="false" :key="i">
							<template #body="{data}">
								<div v-if="col.default">{{ data[col.field] }}</div>
								<div v-if="col.field == 'created_at'">{{ $filter_date(data.created_at) }}</div>
								<div v-if="col.field == 'roles'">
									<Tag v-for="role in data.roles" :key="role.id" :value="role.name" class="mr-1" />
								</div>
								<div v-if="col.field == 'action'">
									<Button v-if="$can('user-edit')" @click="openEdit(data)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary mr-2" />
									<Button v-if="$can('user-delete')" @click="openDelete('single', data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
								</div>
							</template>

							<template v-if="col.filter" #filter="{filterModel,filterCallback}">
								<InputText v-if="col.default" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="column-filter" placeholder="Search" />
								<MultiSelect v-if="col.field == 'roles'" :options="relations.roles" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
							</template>
						</Column>
						<!-- End Columns -->
					</DataTable>

					<!-- Form -->
					<Dialog v-model:visible="dialog.formData" :header="formDataTitle" :style="{width: '450px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="field">
							<label>Name</label>
							<InputText v-model="dataForm.name" autofocus
								:class="{ 'p-invalid': dataForm.errors.has('name') }" />
							<small class="p-error" v-show="dataForm.errors.has('name')">
								{{ dataForm.errors.get('name') }}
							</small>
						</div>
						<div class="field">
							<label>Email</label>
							<InputText v-model="dataForm.email"
								:class="{ 'p-invalid': dataForm.errors.has('email') }" />
							<small class="p-error" v-show="dataForm.errors.has('email')">
								{{ dataForm.errors.get('email') }}
							</small>
						</div>
						<div class="field">
							<label>Role</label>
							<MultiSelect v-model="dataForm.role" :options="relations.roles" display="chip"
								optionValue="id"
								optionLabel="name"
								:class="{ 'p-invalid': dataForm.errors.has('role') }" />
							<small class="p-error" v-show="dataForm.errors.has('role')">
								{{ dataForm.errors.get('role') }}
							</small>
						</div>
						<div class="field">
							<label>Password</label>
							<Password v-model="dataForm.password" mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})." toggleMask
								:class="{ 'p-invalid': dataForm.errors.has('password') }">
								<template #header>
									<h6>Pilih kata sandi</h6>
								</template>
								<template #footer>
									<Divider />
									<p class="mt-2">Saran</p>
									<ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
										<li>Setidaknya satu huruf kecil</li>
										<li>Setidaknya satu huruf besar atau satu angka</li>
										<li>Minimal 6 karakter</li>
									</ul>
								</template>
							</Password>
							<small class="p-error" v-show="dataForm.errors.has('password')">
								{{ dataForm.errors.get('password') }}
							</small>
						</div>
						<div class="field">
							<label>Confirm Password</label>
							<Password v-model="dataForm.password_confirmation" :feedback="false" toggleMask
								:class="{ 'p-invalid': dataForm.errors.has('password_confirmation') }" />
							<small class="p-error" v-show="dataForm.errors.has('password_confirmation')">
								{{ dataForm.errors.get('password_confirmation') }}
							</small>
						</div>

						<template #footer>
							<Button label="Save" @click="submitData" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-text" style="float:right" />
							<Button label="Cancel" @click="dialog.formData = false" icon="pi pi-times" class="p-button-text" />
						</template>
					</Dialog>

					<!-- Delete Confirmation -->
					<Dialog v-model:visible="dialog.deleteData" header="Confirmation" :style="{width: '350px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="confirmation-content">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span>Do you want to delete this record?</span>
						</div>
						<template #footer>
							<Button label="No" icon="pi pi-times" @click="dialog.deleteData = false" class="p-button-text"/>
							<Button label="Yes" icon="pi pi-check" @click="deleteData" class="p-button-text" autofocus />
						</template>
					</Dialog>
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'User',
			api: '/api/user',
			dataForm: new this.$FormAuth({
				mode: null,
				id: null,
				name: null,
				email: null,
				role: null,
				password: null,
				password_confirmation: null,
			}),
			relations: {
				roles: null,
			},
			dataTable: {
				data: [],
				loading: false,
				global_placeholder: 'Name/Email',
				totalRecords: 0,
				rows_option: [10, 25, 50, 100],
				selectedData: [],
				columns: [
					{field: 'name', header: 'Nama', sort: true, filter: true, default: true},
					{field: 'email', header: 'Email', sort: true, filter: true, default: true},
					{field: 'roles', header: 'Role', sort: false, filter: true},
					{field: 'created_at', header: 'Registerd At', sort: true, filter: false},
					{field: 'action', header: 'Action', sort: false, filter: false},
				],
				selectedColumns: ['name', 'email', 'roles', 'action'],
				params: {
					filters: {
						global: {value: null},
						name: {value: null},
						email: {value: null},
						roles: {value: null},
					},
					rows: 10, //per_page
					page: 0,
					sortField: null,
					sortOrder: null,
				},
			},
			dialog: {
				formData: false,
				deleteData: false,
			},
			formDataTitle: null,
		}
	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			this.dataTable.loading = true;
			(event) ? this.dataTable.params = event : null;
			this.$httpAuth.get(this.api, {
				params: {
					rows: this.dataTable.params.rows,
					sortField: this.dataTable.params.sortField,
					sortOrder: this.dataTable.params.sortOrder,
					filters: this.dataTable.params.filters,
					page: this.dataTable.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataTable.loading = false;
				this.dataTable.data = response.data.data;
				this.dataTable.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataTable.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

		getRoles(){
            this.$httpAuth.get(this.api + '/get/roles')
            .then((response) => {
                this.relations.roles = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		openNew() {
			this.resetForm();
			this.dataForm.mode = 'add';
			this.formDataTitle = 'Tambah '+this.title;
			this.dialog.formData = true;
		},
		openEdit(data){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/' + data.id + '/edit')
			.then((response) => {
				this.$Progress.finish();
				this.resetForm();
				this.dataForm.fill(response.data);
				this.dataForm.mode = 'edit';
				this.formDataTitle = 'Edit '+this.title;
				this.dialog.formData = true;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		openDelete(mode = null, data = null){
			if(mode == 'single'){
				this.dataTable.selectedData = [];
				this.dataTable.selectedData.push(data);
			}
			this.dialog.deleteData = true;
		},
		resetForm(){
			this.dataForm.clear();
			this.dataForm.reset();
		},
		submitData(){
			this.$Progress.start();
			if(this.dataForm.mode == 'add'){
				this.dataForm.post(this.api)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			} else if(this.dataForm.mode == 'edit') {
				this.dataForm.put(this.api + '/' + this.dataForm.id)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
					this.$e.emit('getUserCredentials');
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			}
		},
		deleteData() {
			this.$Progress.start();
			this.dialog.deleteData = false;
			const selectedId = this.dataTable.selectedData.map(value => value.id);
			this.$httpAuth.delete(this.api + '/delete', {
				params: {
					id: selectedId
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	watch: {
		'dataTable.params.filters': function(){
			this.loadData();
		},
    },
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
		this.getRoles();
	},
}
</script>